<template>
  <v-container fluid>
    <div class="px-11 ml-4">
      <!-- <v-skeleton-loader type="heading" :loading="pageLoading" :transition="'fade-transition'">
        <p class="font-weight-medium title darkGrey--text">Your Applications</p>
      </v-skeleton-loader>-->
      <v-row>
        <v-col cols="12" md="2" class="text-center" v-for="(app, index) in vg_applications" :key="app.id + '_' + index">
          <v-skeleton-loader  type="image" :loading="pageLoading" :transition="'fade-transition'">
            <v-card :disabled="disableApp(app)" @click="goToApp(app)" class="mx-2">
              <v-icon class="py-8" size="60">apps</v-icon>
            </v-card>
          </v-skeleton-loader>
          <p class="font-weight-medium mt-1 body-2 text-capitalize mb-0">{{app.display_name}}</p>
          <!-- <v-skeleton-loader type="heading" :loading="pageLoading" :transition="'scale-transition'">
          </v-skeleton-loader>-->
        </v-col>
      </v-row>
    </div>
    <!-- Create New App Icon -->
    <!-- <v-tooltip left v-if="isUnoappUser || isSuperUser">
      <template v-slot:activator="{ on }">
        <v-btn
          @click="createNew.dialog = true"
          fab
          fixed
          dark
          bottom
          right
          color="primary"
          v-on="on"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Create An Application</span>
    </v-tooltip>-->
    <!-- <v-dialog
      v-model="createNew.dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col class="pa-0" cols="auto">
              <v-icon color="darkGrey--text text-lighten-1" class="mt-2 mr-3">apps</v-icon>
            </v-col>
            <v-col class="pa-0" cols="auto">Create an application</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="createNew.name" flat name="App Name" label="Name"></v-text-field>
          <v-text-field v-model="createNew.display_name" flat name="App Name" label="Display Name"></v-text-field>
          <v-text-field
            v-model="createNew.description"
            flat
            name="Description"
            label="Add a description"
          ></v-text-field>
          <v-text-field v-model="createNew.identifier" flat name="Identifier" label="Identifier"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createNew.dialog = false" color="grey" text class="text-capitalize">Cancel</v-btn>
          <v-btn @click="create" elevation="0" color="primary" class="text-capitalize">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthAPI } from '../../clients/auth/auth.api'
import { EventBus } from '@/event-bus'

export default {
  async mounted() {
    this.pageLoading = true
    await this.va_setApplications(this.vg_activeBusiness.id)
    this.pageLoading = false
    EventBus.$on('fetch-mounting-methods', async () => {
      this.pageLoading = true
      await this.va_setApplications(this.vg_activeBusiness.id)
      this.pageLoading = false
    })
  },
  data: function () {
    return {
      applications: [],
      createNew: {
        dialog: false,
        name: '',
        display_name: '',
        description: '',
        identifier: '',
        image_url: 'imgur.com'
      },
      addToBusiness: {
        status: false,
        environment: 'dev',
        api_url: 'https://api.unoapp.io',
        admin_url: 'https://apps.unoapp.io',
        api_headers: {}
      },
      apiHeaders: [
        { header_key: 'app_id', header_value: '1234567890' }
      ],
      businesses: [],
      selectedBusiness: null,
      createdApp: null,
      pageLoading: false
    }
  },
  methods: {
    ...mapActions({
      va_setApplications: 'applications/setApplications'
    }),
    async getAllBusinesses() {
      let response
      try {
        response = await AuthAPI.getAllBusinesses()
      } catch (error) {
        return
      }
      this.businesses = response.data.payload
    },
    addHeader() {
      this.apiHeaders.push({ 'key': '', 'value': '' })
    },
    removeHeader() {
      this.apiHeaders.pop()
    },
    goToApp(app) {
      if (app.app_details.admin_url.includes('token')) {
        window.open(app.app_details.admin_url, '_blank')
      }
    },
    disableApp(app) {
      if (app.app_details.admin_url) {
        return !app.app_details.admin_url.includes('token')
      }
      return true
    }
  },
  computed: {
    ...mapGetters({
      vg_user: 'auth/user',
      vg_activeBusiness: 'auth/activeBusiness',
      vg_applications: 'applications/applications'
    }),
    isUnoappUser() {
      return this.vg_user.type === 'unoapp'
    },
    isSuperUser() {
      return this.vg_user.type === 'super'
    },
    getApiHeaders() {
      let data = {}
      for (const item of this.apiHeaders) {
        data[item.header_key] = item['header_value']
      }
      return data
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
